.videoContainer {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto 40px;
}

.video {
  background-color: var(--text-color);
}

.fullSize {
  grid-column-start: 1;
  grid-column-end: 3;
}

.chat {
  border: 1.5px solid var(--decor-grey);
}

.optionsRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

@media (max-width: 1200px) {
  .videoContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background: none;
  }

  .video {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .chat {
    justify-content: flex-end;
    height: 500px;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .optionsRow {
    grid-row-start: 2;
    grid-row-end: 3;

    grid-column-start: 1;
    grid-column-end: 2;

    align-items: flex-start;
    padding: 20px 10px 0 0;
  }
}

@media (max-width: 900px) {
  .optionsRow {
    padding: 20px 10px 0 10px;
  }

  .optionsFullSize {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media (max-width: 600px) {
  .videoContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 40px auto;
  }

  .gridFullSize {
    grid-template-rows: 320px 1fr;
  }

  .fullSize {
    max-height: 300px;
  }

  .chat {
    justify-content: flex-end;
    height: 400px;
    grid-column-start: 1;
    grid-column-end: 3;
    border: none;
  }

  .optionsRow {
    padding: 10px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media (max-width: 400px) {
  .gridFullSize {
    grid-template-rows: 220px 1fr;
  }

  .fullSize {
    max-height: 200px;
  }
}
