.main {
  background-color: var(--black);
  border-radius: 30px;
  min-height: 30px;
  padding: 0 45px;

  display: flex;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  align-items: center;
}

.text {
  padding: 30px 10px 30px 0;
  max-width: 35%;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--text-white);
  max-width: 380px;
}

.image {
  object-fit: contain;
  width: 390px;
  height: 190px;
  padding-bottom: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35%;
  padding-left: 10px;
  min-width: 170px;
}

.button button {
  background-color: var(--black);
  color: var(--text-white);
}

.imageMobile {
  display: none;
}

@media (max-width: 800px) {
  .main {
    flex-direction: column;
    padding: 0 25px 25px;
  }

  .image {
    display: none;
  }

  .imageMobile {
    display: block;
    margin-bottom: 30px;
    width: 70%;
  }

  .text {
    padding: 0;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .button {
    justify-content: flex-start;
    width: 100%;
    padding-left: 0;
  }
}
