.btn {
  font-size: 0.6rem;
  border-radius: 40px;
  padding-top: 12px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 11px;
}

.btn:hover {
  cursor: pointer;
}

.primary {
  background-color: var(--text-hover-color);
  border: solid 1px var(--text-hover-color);

  color: var(--text-white);
}

.grey {
  background-color: var(--grey-black);
  border: solid 1px var(--grey-black);

  color: var(--text-white);
}

.white {
  background-color: var(--text-white);
  border: solid 1px var(--text-white);

  color: var(--text-color);
}
