.itemModule {
  padding-bottom: 24px;
  border-bottom: 1.5px solid var(--decor-grey);
  margin-bottom: 24px;
}

.row {
  display: flex;
}

.checkIcon {
  margin-top: 2px;
  margin-right: 16px;
  margin-left: -2px;
}

.check {
  margin-top: 4px;
  border: 2px solid #e0e1e4;
  margin-right: 18px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}

.itemNumber {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  color: var(--grey-black);
}

.module {
  flex: 1;
  margin-bottom: 6px;
}

.titleRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.titleRow img {
  transition: 0.3s transform;
}

.activeIMG {
  transform: rotate(-180deg);
}

.titleProgress {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--grey-black);
  margin-left: 16px;
  cursor: pointer;
  display: flex;
}

.titleProgress span {
  margin-right: 12px;
}

.content {
  margin-left: 38px;
}

.contentItemDecor {
  margin-left: 9px;
  width: 2px;
  height: 16px;
  margin-bottom: 8px;

  background-color: var(--decor-grey);
}

/* .contentItemActive .contentItemDecor {
  background-color: var(--decor-color);
} */

.contentTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
}

.contentItemActive .contentTitle {
  color: var(--grey-black);
}

.contentType {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
