.decor {
  width: 2px;
  height: 16px;
  margin-left: 11px;
  background-color: var(--decor-grey);
  border-radius: 2px;
}

.icon_row {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 6px;
  margin-top: 14px;
}

.icon {
  position: relative;
  margin-right: 16px;
  width: 24px;
  height: 24px;
}

.type {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--grey-black);
  text-transform: capitalize;

  margin-top: 4px;
}
@media (max-width: 1080px) {
  .icon_row {
    font-size: 0.61rem;
    margin-top: 15px;
    margin-bottom: 8px;
  }
}
@media (max-width: 570px) {
  .icon_row {
    font-size: 0.61rem;
    margin-top: 15px;
    margin-bottom: 8px;
  }
  .icon {
    margin-left: 3.5px;
    margin-right: 43.5px;
    width: 17px;
    height: 17px;
  }
}

@media (max-width: 470px) {
  .icon {
    margin-right: 38.5px;
  }
}

@media (max-width: 450px) {
  .decor {
    height: 15px;
  }
  .icon_row {
    font-size: 0.5rem;
  }
  .type {
    margin: 0px;
  }
}
@media (max-width: 390px) {
  .icon {
    margin-left: 3.5px;
    margin-right: 28px;
    width: 17px;
    height: 17px;
  }
}
@media (max-width: 340px) {
  .icon {
    margin-right: 29px;
    width: 15px;
    height: 15px;
  }
  .decor {
    height: 10px;
  }
  .icon_row {
    font-size: 0.5rem;
  }
  .type {
    margin: 0px;
    font-size: 12px;
  }
}
