.main {
  min-height: 100vh;
  padding: 100px;
  margin: 0 auto;
  max-width: 1700px;
}

.paid {
  height: 72px;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: var(--text-color);

  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: var(--text-white);
  text-transform: uppercase;

  margin-bottom: 50px;
}

.container {
  padding: 16px;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;

  color: var(--black);

  margin-bottom: 32px;
}

.row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 40px;
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 146.16%;

  white-space: pre-line;
}

.btnBlock {
  margin-left: 100px;
  display: block;
}

.btn {
  padding-right: 100px;
  min-width: 230px;
}

.image {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .main {
    padding: 50px;
  }
}

@media (max-width: 900px) {
  .main {
    padding: 0px;
  }

  .container {
    padding: 0 15px;
  }
}

@media (max-width: 750px) {
  .paid {
    margin-bottom: 31px;
  }

  .title {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;
  }

  .row {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 30px;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
    color: var(--grey-black);

    margin-bottom: 30px;
  }
  .btnBlock {
    margin: 0;
  }
  .btn {
    padding-right: 20px;
    min-width: auto;
  }
  .image {
    height: 330px;
  }
}
