.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--grey-black);
  margin-bottom: 29px;
  margin-top: 22px;
}

.card {
  padding: 16px 11px 16px 16px;

  background-color: rgba(232, 239, 240, 0.5);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background-color 0.5s;
}

.card:hover {
  background-color: #2f3d46;
}
.card:hover .card_text {
  color: var(--text-white);
}

.card_text {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #2f3d46;

  margin-bottom: 22px;
}

.description {
  margin-top: 30px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
  white-space: pre-line;

  color: rgba(47, 61, 70, 0.5);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 265px;
}
