.progress {
  display: flex;
  justify-content: space-between;
}

.row__progress {
  width: 100%;
  display: flex;
  align-items: center;
}

.row__progress button {
  margin-right: 0.5rem;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid rgba(68, 68, 68, 0.913);
  color: rgb(0, 0, 0);
}

.progress_background {
  width: 100%;
  transition: 0.3s width;
  border-radius: 30px;
  height: 4px;
  background-color: rgba(186, 186, 186, 0.811);
}

.progress_item {
  transition: 0.1s width;
  border-radius: 30px;
  height: 100%;
  background-color: rgb(0, 66, 10);
}

@media (max-width: 1180px) {
  .row__progress button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    padding: 12px 20px;
    border-width: 1px;
  }
}
