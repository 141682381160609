.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 175px;
  margin-bottom: 40px;
}

.logo_menu {
  display: flex;
  align-items: center;
}

.ImgLogoMenu {
  width: 77px;
  height: 66px;
}

.ImgLogoMenu img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.userMenu {
  display: flex;
  align-items: center;
}

.langs {
  margin-left: 50px;
}

.lang {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border: 1px solid var(--decor-color);
  border-radius: 20px;
  cursor: pointer;
}

.langsDropWrapper {
  position: absolute;
  visibility: hidden;
  opacity: 0;

  transition: 0.4s;
  z-index: 10;
  margin-left: -14px;
  padding-top: 10px;
}

.langs:hover .langsDropWrapper {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s, 0s, 0.15s;
}

.langsDrop {
  box-shadow: 0 4px 40px rgb(76 94 115 / 21%);
  background: var(--text-color);
  border-radius: 8px;
  padding: 24px;

  color: var(--text-white);
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.langsDropItems {
  margin-bottom: 24px;
  cursor: pointer;
}
.langsDropItems:last-child {
  margin-bottom: 0;
}

.langsDropItemsActive {
  text-decoration-line: underline;
  color: var(--grey-black);
}

@media (max-width: 550px) {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    margin-bottom: 20px;
  }

  .ImgLogoMenu {
    width: 53px;
    height: 45px;
  }
}
