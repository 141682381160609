.title {
  display: none;
}

.contentText {
  overflow-wrap: break-word;
  padding: 16px;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  overflow-y: auto;
  /* background-color: #fafafa; */
}

.contentText a {
  color: #0057ff;
}

@media (max-width: 900px) {
  .title {
    padding: 16px;
    display: block;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
  }
}
