:root {
  --text-color: #000;
  --text-light: rgb(101, 104, 120);
  --grey-black: #656878;
  --text-white: #fff;

  --text-hover-color: #1b612a;
  --decor-color: #136401;
  --decor-grey: #d6d7d9;

  --text-transaction: #656878;

  --danger-color: #e15e41;
  --filter-green: brightness(0) saturate(100%) invert(28%) sepia(24%)
    saturate(1426%) hue-rotate(81deg) brightness(93%) contrast(88%);

  --black: #12121a;
}

html {
  font-size: 24px;
  line-height: 110%;
}

body {
  font-family: "PF Bague Sans Pro";
  /* background-color: #fafafa; */
}

button {
  font-family: "PF Bague Sans Pro";
}

input {
  font-family: "PF Bague Sans Pro";
}

.container {
  margin: 0 auto;
  padding: 0 100px;
  max-width: 1920px;
}

h1 {
  border-bottom: 5px solid var(--decor-color);
  padding-bottom: 20px;
}

.h1_grey {
  border-bottom: 1px solid var(--decor-grey);
  font-size: 0.8rem;
  font-weight: 400;
}

h2 {
  border-left: 4px solid var(--decor-color);
  padding-left: 15px;
  line-height: 120%;
  font-weight: 500;
}

a {
  color: inherit;
}

li {
  list-style: none;
}

.scale {
  transition: transform ease 0.2s;
}

.scale:hover {
  transform: scale(1.1);
}

.body {
  min-height: calc(100vh - 175px);
}

.arrow_left {
  margin-right: 15px;
  height: 12px;
  cursor: pointer;
  margin-top: -4px;
}

.decorLine {
  height: 1px;
  width: 100%;
  background: var(--decor-grey);
}

@media (max-width: 990px) {
  .container {
    padding: 0 50px;
  }
}

@media (max-width: 700px) {
  .container {
    padding: 0 30px;
  }

  h1 {
    font-size: 0.8rem;
    padding-bottom: 15px;
  }
}

@media (max-width: 460px) {
  .container {
    padding: 0 20px;
  }
  .body {
    min-height: calc(80vh - 200px);
  }
  h1 {
    font-size: 0.75rem;
  }

  .decorLine {
    height: 1px;
  }
}
@media (max-width: 400px) {
  .body {
    min-height: calc(80vh - 150px);
  }
}
@media (max-width: 310px) {
  .body {
    min-height: calc(70vh - 100px);
  }
}
@media (max-width: 290px) {
  .container {
    padding: 0 10px;
  }
}
