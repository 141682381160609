.descrItem {
  margin-bottom: 50px;
  background: #fafafa;
}

.topDescr {
  background: var(--text-color);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;

  margin-bottom: 40px;

  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: var(--text-white);
  text-transform: uppercase;
}

.content {
  padding: 0 16px 16px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 24px;
  /* width: 80%; */
}

@media (max-width: 900px) {
  .topDescr {
    flex-direction: row-reverse;
  }

  .descrItem {
    margin-bottom: 30px;
  }

  .content {
    padding: 0 16px 30px;
  }
}
