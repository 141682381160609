.table_header {
  background-color: var(--text-transaction);
  color: #fff;
}
.body {
  min-height: calc(10vh - 175px);
}
.noItems {
  font-size: 20px;
  margin: 20px 0;
  color: var(--text-transaction);
}
.table_transactions {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.table_header tr {
  text-align: left;
}

.table_header tr th {
  transition: 0.1s;
  text-align: left;
  font-size: 15px;
  line-height: 15px;
  border-right: 0.01rem solid rgba(255, 255, 255, 0.231);
}

table tr:nth-child(2n) td {
  background: #f6f6f6;
}

table tr:nth-child(2n + 1) td {
  background: #ffff;
}
.title_transact h3 {
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 70px 0;
}

.order_table {
  padding: 10px;
}
.product_table {
  padding: 10px;
}
.date_table {
  padding: 10px;
}
.lastdate_table {
  padding: 10px;
}
.status_table {
  padding: 10px;
}
.price_table {
  padding: 10px;
}
.row_currency {
  display: flex;
}
.body_table tr td {
  font-size: 15px;
  line-height: 15px;
  border: 0.01rem solid rgba(60, 60, 60, 0.231);
}

@media (max-width: 1220px) {
  .title_transact h3 {
    font-size: 50px;
    font-weight: 500;
    margin: 0 0 70px 0;
  }
  .table_header tr th {
    font-size: 12px;
  }
  .body_table tr td {
    font-size: 10px;
  }
}
@media (max-width: 1100px) {
  .table_header td {
    text-align: center !important;
  }
}

.title_transactMobile h3 {
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 70px 0;
}
.element_transaction {
  border-radius: 8px;
  padding: 30px 30px;
  margin: 50px 0;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.top_content {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: #e0e0e0 2px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_content span {
  font-size: 20px;
  color: var(--text-transaction);
}
.price_content p {
  padding: 8px 0;
}
.top_content p {
  font-size: 18px;
}
.mobile_row_currency {
  display: flex;
  margin: 10px 0;
}
.priceMobile {
  font-size: 16px;
  font-weight: 600;
}
.last_day_block {
  padding-top: 2px;
  position: relative;
  bottom: 2px;
}
.bottom_content span {
  font-size: 20px;
  color: var(--text-transaction);
}
.item_top p {
  margin: 8px 0;
}
.bottom_content p {
  font-size: 18px;
}
.product_item {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: #e0e0e0 2px solid;
}
.product_item p {
  margin: 20px 0;
}
.status_item div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 820px) {
  .title_transactMobile h3 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .title_transactMobile h3 {
    font-size: 35px;
    margin: 30px 0 30px 0;
  }
}
@media (max-width: 450px) {
  .title_transactMobile h3 {
    font-size: 35px;
    margin: 30px 0 50px 0;
  }
  .mobile_row_currency {
    margin: 8px 0;
  }
  .priceMobile {
    font-size: 13px;
  }
  .top_content span {
    font-size: 15px;
    color: var(--text-transaction);
  }
  .top_content p {
    font-size: 14px;
  }
  .status_item p {
    font-size: 12px !important;
  }
  .bottom_content p {
    font-size: 14px;
  }
  .bottom_content span {
    font-size: 15px;
  }
  .element_transaction {
    padding: 15px 15px;
    margin: 30px 0;
    background-color: #f0f0f0;
  }
}
