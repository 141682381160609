.item {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--decor-grey);
  margin-bottom: 50px;
}

.moduleRow {
  display: flex;
  align-items: stretch;
}

.itemNumberWrapper {
  display: flex;
  align-items: center;
}

.itemNumber {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: var(--text-white);
  background: #656878;
  border-radius: 40px;

  margin-right: 50px;
}

.itemTitle {
  margin-top: 4px;
  flex: 1;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  flex-direction: column;
}

.itemNumberTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--grey-black);
  margin-bottom: 8px;
}

.titleRow {
  word-wrap: break-word;
}
.BigtitleRow {
  word-wrap: break-word;
  margin-bottom: 15px;
}

.btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--grey-black);
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.chevron {
  margin-left: 15px;
  -webkit-transition: 0.2s -webkit-transform;
  transition: 0.2s -webkit-transform;
  -o-transition: 0.2s transform;
  transition: 0.2s transform;
  transition: 0.2s transform, 0.2s -webkit-transform;
}
.chevronActive {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.contentWrapper {
  padding-left: 130px;
}
@media (max-width: 1080px) {
  .itemNumberTitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: var(--grey-black);
    margin-bottom: 4px;
  }
  .titleRow {
    font-size: 20px;
  }
  .BigtitleRow {
    font-size: 20px;
  }
  .btn span {
    font-size: 15px;
  }
  .itemNumber {
    width: 50px;
    height: 50px;
    font-size: 23px;
    line-height: 35px;
    margin-right: 40px;
    position: relative;
    bottom: 15px;
  }
  .contentWrapper {
    padding-left: 90px;
  }
}
@media (max-width: 800px) {
  .btn span {
    display: none;
  }
}
@media (max-width: 550px) {
  .item {
    border-bottom: 1px solid var(--decor-grey);
  }
}
@media (max-width: 540px) {
  .itemNumber {
    width: 40px;
    height: 40px;
    font-size: 19px;
    margin-right: 30px;
    line-height: 35px;
    bottom: 10px;
  }
  .itemNumberTitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: var(--grey-black);
    margin-bottom: 6px;
  }
  .titleRow {
    font-size: 17px;
    line-height: 20px;
  }
  .BigtitleRow {
    font-size: 17px;
    line-height: 20px;
  }
  .chevron {
    position: relative;
    bottom: 10px;
  }
  .contentWrapper {
    padding-left: 8px;
  }
}
@media (max-width: 473px) {
  .BigtitleRow {
    font-size: 15px;
  }
  .itemNumber {
    width: 37px;
    height: 37px;
    margin-right: 27px;
  }
  .itemNumberTitle {
    font-size: 12px;
    position: relative;
    top: 4px;
  }
  .contentWrapper {
    padding-left: 6.5px;
  }
}
@media (max-width: 390px) {
  .BigtitleRow {
    font-size: 10px;
    line-height: 15px;
  }
  .itemNumber {
    bottom: 0;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    font-size: 13px;
    margin-bottom: 8px;
  }
  .itemNumberTitle {
    font-weight: 400;
    font-size: 10px;
    color: var(--grey-black);
  }
  .contentWrapper {
    padding-left: 3px;
  }
}
