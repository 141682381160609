.courcesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;

  margin-top: 50px;
}

@media (max-width: 990px) {
  .courcesGrid {
    grid-gap: 50px;
  }
}

@media (max-width: 700px) {
  .courcesGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 460px) {
  .courcesGrid {
    grid-gap: 25px;
    margin-top: 25px;
  }
}
