.item_wrapper {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--decor-grey);
}

.item {
  transition: transform 0.5s ease;
  cursor: pointer;
}
.item_img {
  margin-bottom: 32px;
  position: relative;
}

.item_image {
  object-fit: cover;
  width: 100%;
  height: 320px;
}

.streamTag {
  position: absolute;
  top: 20px;
  right: 30px;
  background-color: var(--text-white);
  border-radius: 74px;

  padding: 6px 7px 6px 15px;

  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: var(--grey-black);
}

.streamTag span {
  margin-top: 2px;
}

.streamTagIcon {
  width: 23px;
  height: 17px;
  margin-left: 9px;
}

.item_title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 32px;
}

.item_descr {
  color: var(--text-light);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 10px 0px 20px 0px;
}
.startCours {
  width: 120px;
  margin-left: 50px;
}
.item_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item_row .price {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
}

.item_row .button {
  margin-left: 30px;
  border: 1px solid var(--decor-grey);
}

.item:hover {
  transform: translateY(-10px);
}
@media (max-width: 1180px) {
  .startCours {
    font-size: 10px;
  }
}
@media (max-width: 990px) {
  .item_img {
    margin-bottom: 25px;
  }

  .item_subTitle {
    font-size: 0.4rem;
  }

  .item_title {
    line-height: 28px;
    font-size: 1rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 460px) {
  .item_img {
    margin-bottom: 25px;
  }
  .item_descr {
    font-size: 13px;
  }
  .item_subTitle {
    font-size: 0.38rem;
  }

  .item_title {
    font-size: 22px;
    line-height: 23px;
    margin-bottom: 25px;
  }

  .item_wrapper {
    padding-bottom: 25px;
  }
}
@media (max-width: 320px) {
  .item_img img {
    height: 180px;
  }

  .startCours {
    margin-left: 10px;
  }
}

/* PROGRESS BAR */
@media (max-width: 850px) {
  .progressBar {
    display: none;
  }
}

@media (max-width: 700px) {
  .progressBar {
    display: block;
  }
}

@media (max-width: 450px) {
  .progressBar {
    display: none;
  }
}
