.block {
  padding: 0 16px;
}

.titleData {
  font-size: 30px;
  line-height: 36px;
  margin: 50px 0;
}

.contentText {
  overflow-wrap: break-word;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  overflow-y: auto;
  margin-bottom: 27px;
}

.block button {
  cursor: default;
}
