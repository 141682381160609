.course_top {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  border-bottom: 1px solid var(--decor-grey);
  margin-bottom: 100px;
}

.cource_title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  margin-bottom: 60px;
  width: 65%;
}

.cource_img img {
  object-fit: cover;

  height: 570px;
  width: 100%;
}

.cource_descr {
  color: var(--text-light);
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  white-space: pre-line;

  padding-right: 10px;
  padding-bottom: 100px;
  border-bottom: 1px solid var(--decor-grey);
  margin-bottom: 100px;
}

.cource_descr p {
  width: 50%;
}

.cource_descr a {
  color: var(--decor-color);
}

/* Meida */

@media (max-width: 1100px) {
  .course_top {
    padding-bottom: 70px;
    margin-bottom: 70px;
  }
  .cource_title {
    font-size: 40px;
    line-height: 45px;
    width: 100%;
  }

  .cource_img img {
    height: 450px;
  }

  .cource_descr {
    padding-bottom: 70px;
    margin-bottom: 70px;
  }

  .cource_descr p {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .course_top {
    flex-direction: column-reverse;
    padding-bottom: 0;
    margin-bottom: 25px;
    border-width: 1px;
  }

  .cource_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 25px 0;
  }

  .cource_img img {
    height: 180px;
  }

  .cource_descr {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-width: 1px;
  }
}
