.menu__nav {
  display: flex;
  align-items: center;
  opacity: 1;
  position: relative;
  left: 30px;
}

.menu__nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding-left: 2rem;
  -webkit-transition: 0.5s width;
  -o-transition: 0.5s width;
  transition: 0.5s width;
  width: 100%;
  max-width: 565px;
  overflow: hidden;
}
.menu__nav ul.activeNav {
  width: 0;
  overflow: hidden;
}

.menu__nav ul.activeNav li {
  white-space: nowrap;
}

.active__nav_item {
  color: rgb(193, 187, 180) !important;
}

.menu__nav ul li {
  white-space: nowrap;

  font-size: 13.9px;
  cursor: pointer;
  margin: 0 0.8rem;
  color: rgb(66, 66, 66);
  text-decoration: none;
  -webkit-transition: color ease 0.2s;
  -o-transition: color ease 0.2s;
  transition: color ease 0.2s;
  will-change: color;
}
.menu__nav ul li:hover {
  color: var(--text-color);
}

.activeLink {
  color: rgb(214, 215, 217) !important;
  /* color: var(--text-light); */
}

.ArrowLeftSpan {
  cursor: pointer;
  margin-left: 0.2rem;
  position: relative;
  top: 0.1rem;
}

.activeArrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
