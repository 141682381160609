.support {
  display: flex;
  justify-content: space-around;
}
.title__leftSupport h3 {
  font-size: 60px;
  font-weight: 500;
  margin-bottom: 40px;
}
.content__leftSupport span {
  font-size: 18px;
  color: rgb(107, 107, 107);
}
.content__leftSupport p {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 300;
  color: rgb(107, 107, 107);
}

.content__leftSupport p a {
  text-decoration: underline;
}

.rightBlock__supportPage {
  display: flex;
  margin-left: 150px;
  margin-top: 10px;
}
.inputsBlock {
  display: flex;
  position: relative;
  left: 30px;
}
.title__right h3 {
  margin-bottom: 40px;
  font-size: 25px;
  position: relative;
  left: 55px;
}

.input__content {
  margin-bottom: 50px;
  padding: 10px 30px;
}
.input__content span {
  font-size: 13px;
  font-weight: 500;
}
.input__content div input {
  width: 250px;
  background-color: rgba(255, 255, 255, 0);
}

.comment__input span {
  font-size: 13px;
  font-weight: 500;
}
.comment__input {
  margin-left: 60px;
  margin-bottom: 40px;
}
.comment__input input {
  background-color: rgba(255, 255, 255, 0);
}

.form_button {
  display: flex;
  justify-content: flex-end;
}
.form_button button {
  width: 250px;
}

@media (max-width: 1330px) {
  .title__right h3 {
    font-size: 25px;
    position: relative;
    left: 45px;
  }
  .inputsBlock {
    left: 20px;
  }
  .comment__input {
    margin-left: 50px;
  }
  .form_button {
    margin-left: 50px;
  }
}

@media (max-width: 1280px) {
  .input__content {
    padding: 10px 20px;
  }
  .input__content div input {
    transition: 0.2s;
    width: 200px;
  }
  .comment__input {
    margin-left: 40px;
  }
  .title__leftSupport h3 {
    font-size: 55px;
  }
  .content__leftSupport span {
    font-size: 15px;
  }
  .content__leftSupport p {
    margin-top: 30px;
    font-size: 15px;
  }
  .rightBlock__supportPage {
    margin-left: 90px;
    padding-right: 10px;
  }
}

@media (max-width: 1080px) {
  .support {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .rightBlock__supportPage {
    display: flex;
    justify-content: center;
    position: relative;
    left: 10px;
    margin: 80px 0px;
    margin-left: 100px;
  }
  .form_button {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .input__content div input {
    margin-bottom: 10px;
    width: 250px;
  }
  .comment__input {
    text-align: left;
    margin-left: 30px;
    margin-right: 29px;
  }
  .rightBlock__supportPage {
    display: flex;
    margin-left: 0px;
    padding-right: 10px;
  }
  .title__right h3 {
    font-size: 25px;
    position: relative;
    left: 0;
  }
  .inputsBlock {
    text-align: left;
    position: relative;
    left: 0px;
  }
  .input__content {
    margin-bottom: 50px;
    padding: 10px 30px;
  }
  .form_button button {
    width: 350px;
  }
}

@media (max-width: 720px) {
  .title__leftSupport h3 {
    margin-top: 30px;
  }
  .rightBlock__supportPage {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin: 40px 0;
  }
  .input__content {
    padding: 20px 0px;
  }
  .input__content div input {
    margin-bottom: 10px;
    width: 100%;
  }
  .contentFlex {
    display: block;
    position: relative;
    right: 10px;
  }
  .support {
    display: block;
    text-align: left;
  }
  .inputsBlock {
    display: block;
  }
  .form__content {
    display: block;
  }
  .input__content {
    padding: 0px;
  }
  .title__right {
    display: none;
  }
  .comment__input {
    text-align: left;
    margin-left: 0px;
    margin-right: 0px;
  }
  .form_button button {
    width: 100%;
    margin-right: 0px;
  }
}

@media (max-width: 500px) {
  .title__leftSupport h3 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  .content__leftSupport span {
    font-size: 13px;
    color: rgb(107, 107, 107);
  }
  .content__leftSupport p {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 300;
    color: rgb(107, 107, 107);
  }
}
