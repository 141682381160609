.title {
  display: none;
}

.videoContainer {
  height: auto;
  width: 100%;
}

.video {
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .title {
    padding: 16px;
    display: block;
    font-size: 22px;
    line-height: 25px;
    font-weight: 500;
  }

  .videoContainer {
    height: auto;
  }
}
