.login {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 50px;
}

.input_register {
  border: none;
  margin: 15px 0;
}
.text_footer {
  margin-top: 20px;
  line-height: 17px;
  font-size: 16px;
  width: 75%;
  text-align: left;
  color: gray;
  font-weight: 400;
  font-size: 13px;
}

.text_footer span {
  padding-bottom: 0.05px;
  text-decoration: underline 1px rgba(0, 0, 0, 0.537);
}
.footer_register {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.loginBtn {
  font-size: 14px;
  color: #000;
  width: 115px;
  background-color: inherit;
}
.login_form button {
  font-size: 14px;
  margin-top: 30px;
}
.create_button {
  width: 155px;
  font-size: 12px;
}
.login_title {
  font-weight: 500;
  transition: 0.5s;
  font-size: 24px;
  margin-bottom: 50px;
}
.block_inputs {
  width: 100%;
  margin: 20px 0;
}
@media (max-width: 500px) {
  .input_register{
    font-size: 15px !important;
  }
  .login {
    margin: 30px 10px;
  }

  .create_button {
    width: 140px;
    font-size: 12px !important;
  }
  .loginBtn {
    width: 90px;
  }
  .text_footer a {
    font-size: 13px;
  }
}
@media (max-width: 378px) {
  .login_form button{
    margin-top: 13px;
  }
  .login {
    margin: 5px 0px;
  }
  .login_title {
    margin-bottom: 30px;
  }
  .loginBtn {
    width: 80px;
    padding: 10px !important;
    font-size: 13px !important;
  }
  .text_footer {
    text-decoration: none;
    margin-top: 20px;
    width: 100%;
    line-height: 15px;
  }
  .text_footer a {
    font-size: 12px !important;
  }
  .create_button {
    padding: 10px;
    width: 130px;
    font-size: 12px !important;
  }
  .login_form input {
    font-size: 14px;
    letter-spacing: 0px;
  }
}
