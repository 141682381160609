.Forgot_Modal {
  padding: 20px 40px;
}
.title_modal h3 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
}
.text_modal p {
  font-size: 13px;
  line-height: 16px;
  color: #656878;
}
.title_modal {
  margin-bottom: 35px;
}
.body_modal {
  margin-bottom: 70px;
}
.body_modal input {
  width: 100%;
}

@media (max-width: 500px) {
  .footer_modal{
    width: 100%;
  }
  .body_modal input {
    font-size: 15px;
    width: 100%;
  }
  .Forgot_Modal {
    padding: 20px 20px;
  }
  .title_modal h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .text_modal p {
    font-size: 13px;
    line-height: 15px;
    color: #656878;
  }
}
@media (max-width: 378px) {
  .body_modal input {
    width: 100%;
  }
  .body_modal{
    margin-bottom: 50px;
    font-size: 14px ;
  }
  .footer_modal button{
    font-size: 11px;
  }
  .Forgot_Modal {
    padding: 10px 10px;
  }
  .title_modal {
    margin-bottom: 25px;
  }
  .title_modal h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 18px;
  }
  .text_modal p {
    font-size: 12px;
    line-height: 13px;
    color: #656878;
  }
}
