.wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 120px;
}

.title {
  margin-bottom: 50px;
  font-size: 20px;
  color: #656878;
}

.link {
  font-size: 0.6rem;
  color: var(--text-light);
}
@media (max-width: 460px) {
  .wrapper {
    padding-top: 0px;
    padding-left: 10px;
    align-items: center;
  }
}
@media (max-width: 400px) {
  .title {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .content button {
    width: 180px;
    font-size: 15px;
  }
}

@media (max-width: 350px) {
  .title {
    font-size: 17px;
    margin-bottom: 35px;
  }
  .content button {
    width: 150px;
    font-size: 12px;
  }
}
