.modalWrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: 0.3s opacity;

  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
}

.modalOpen {
  opacity: 1;
}

.modal {
  width: 460px;
  background: var(--text-white);
  padding: 30px;
}

.modalTop {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 30px;
}

.modalTop img {
  cursor: pointer;
}

@media(max-width: 500px) {
  .modal {
    width: 350px;
    background: var(--text-white);
    padding: 30px;
  }
}


@media(max-width: 378px) {
  .modal {
    margin: 0 10px;
    width: 100%;
    background: var(--text-white);
    padding: 30px;
  }
  .modalTop img {
    cursor: pointer;
    width: 35px;
  }
}
