.login_form {
  padding: 0 53px 50px;
}
.login_title {
  font-weight: 500;
  margin-bottom: 30px;
}
.header_sign_in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create_account {
  width: 155px;
  margin-right: 8px;
  color: #000;
  background-color: inherit;
}
.login_button {
  width: 100px;
}
.login_input {
  padding: 0px !important;
  width: 100%;
  margin-bottom: 30px;
}
.block_inputs {
  width: 100%;
  margin: 20px 0;
}
.fGPassword {
  cursor: pointer;
  font-size: 15px;
  margin: 10px 0;
  bottom: 13px;
  color: #656878;
  position: relative;
  transition: 0.2s color;
}
@media (max-width: 500px) {
  .block_inputs input {
    font-size: 15px !important;
  }
  .block_inputs{
    margin: 10px 0;
  }
  .fGPassword {
    font-size: 13px;
  }
  .login_input{
    margin-bottom: 30px;
  }
  .login_title {
    position: relative;
    font-size: 20px;
  }
  .login_button {
    padding: 10px;
    width: 100px;
    font-size: 13px;
  }
  .create_account {
    padding: 10px;
    width: 140px;
    font-size: 13px;
    margin-right: 10px;
    position: relative;
    left: 2px;
  }
  .login_form {
    position: relative;
    bottom: 10px;
    padding: 0px;
  }
}

@media (max-width: 378px) {
  .login_form {
    position: relative;
    bottom: 10px;
    padding: 0px;
  }
  .create_account {
    padding: 10px;
    width: 100%;
    margin-left: 0px;
    position: relative;
  }
  .login_button {
    width: 100px;
  }
}
@media (max-width: 300px) {
  .create_account{
    font-size: 11px;
  }
  .login_button {
    width: 80px;
    font-size: 11px;
  }
}