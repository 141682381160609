.footer {
  padding-top: 50px;
  padding-bottom: 100px;
}

.footer_logo {
  width: 56px;
  height: 48px;
  cursor: pointer;
}

.footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.social_row {
  display: flex;
  align-items: center;
}

.social_row img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.social_row a {
  margin-left: 32px;
  cursor: pointer;
}

.footer_botton {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--grey-black);
}

.footer_botton .copy {
  opacity: 0.7;
}

.footer_botton .copy br {
  display: none;
}

.payments_row {
  display: flex;
  align-items: center;
}

.payments_row img {
  width: 40px;
  height: 40px;
  margin-right: 32px;
}

.payments_row img:last-child {
  margin-right: 0;
}

@media (max-width: 550px) {
  .footer_top {
    margin-bottom: 30px;
    align-items: center;
  }

  .footer_logo {
    width: 37.33px;
    height: 32px;
  }

  .social_row img {
    width: 19px;
    height: 19px;
  }

  .social_row a {
    margin-left: 16px;
  }

  .footer_botton {
    align-items: center;
    flex-direction: row-reverse;
  }

  .copy {
    font-size: 11px;
    line-height: 13px;
  }

  .footer_botton .copy br {
    display: block;
  }

  .payments_row img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
