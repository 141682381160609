.prices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;
  margin-bottom: 80px;
  margin-top: 100px;
}

.left_contentPrices h3 {
  font-weight: 500;
  font-size: 50px;
  line-height: 52px;
}
.flex_currency{
  display: flex;
}
.right_contentPrices {
  padding-top: 15px;
}

.prices__item {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--decor-grey);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
}

.prices__item:last-child {
  border-bottom: none;
}

.CurrencyPrice {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 10px;
}

.prices__item_descr {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--text-light);
}

.prices__item_btn {
  background-color: var(--decor-color);
  border: solid 1px var(--decor-color);
  font-size: 0.6rem;
  color: var(--text-white);
  border-radius: 40px;
  padding-top: 12px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 11px;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 950px) {
  .prices {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 550px) {
  .prices__item {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 40px;
  }

  .prices {
    grid-column-gap: 30px;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  .left_contentPrices h3 {
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 25px;
  }

  .left_contentPrices {
    border-bottom: 1px solid var(--decor-grey);
    margin-bottom: 25px;
  }

  .prices__item_btn {
    font-size: 11px;
    border-radius: 30px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 11px;
  }

  .CurrencyPrice {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .prices__item_descr {
    font-size: 14px;
    line-height: 16px;
  }

  .prices__item_btn {
    font-size: 14px;
    line-height: 16px;

    border-radius: 20px;
    padding: 12px 24px;
  }
}

@media (max-width: 350px) {
  .prices__item_btn {
    font-size: 12px;
    line-height: 14px;

    border-radius: 20px;
    padding: 10px 14px;
  }
}
