.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 72px;
  background-color: var(--text-color);
  padding: 16px;
}

.content {
  height: calc(100vh - 272px);
}

.title {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: var(--text-white);
}

@media (max-width: 1200px) {
  .content {
    height: calc(100vh - 172px);
  }
}

@media (max-width: 900px) {
  .content {
    height: calc(100vh - 72px);
  }
}
