.menu {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 0 15px 15px;
  height: 100vh;
  width: 100vw;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  transform: translateY(-110vh);
  transition: 0.4s transform ease-in;
  overflow-y: auto;
}

.menu_open {
  transform: translateY(0);
}

.menu_top {
  margin-top: 37.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu_top img {
  cursor: pointer;
}

.content {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.links {
  margin-bottom: 30px;
  text-align: right;
}

.links a {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.links li {
  margin-bottom: 15px;
}

.authBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--text-color);
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 30px;
  max-width: 500px;
}

.authBlock button {
  width: 45%;
}

.signIn {
  background: transparent;
  border: 1px solid var(--text-white);
  border-radius: 20px;
}

.link {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey-black);
}

.lang {
  /* margin-top: 15px; */
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.decor {
  margin: 30px 0;
}

.socialRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.socialRow a {
  margin-left: 32px;
}

.socialRow a:first-child {
  margin-left: 0px;
}

.socialRow img {
  width: 24px;
  height: 24px;
}

.policy {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  text-align: right;

  color: var(--grey-black);

  opacity: 0.7;
}

.langsList {
  padding-top: 25px;
}

.langsList li {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  text-align: right;

  margin-bottom: 30px;
}

.activeLang {
  font-weight: 700 !important;

  color: #1b612a;

  border-bottom: 2px solid #1b612a;
}
