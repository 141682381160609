.file_content {
  padding: 16px;
}
.file_content a {
  display: flex;
  align-items: flex-start;
  text-decoration: underline;
}
.file_content img {
  margin-right: 5px;
}
