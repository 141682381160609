.input {
  margin: 4px 0;
  height: 28px;
  padding: 0 1px 5px;
  border: none;
  font-size: 0.6rem;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
}
.line_input{
  width: 100%;
  height: 1px;
  background-color: rgba(105, 105, 105, 0.553);
}
.line_input:focus {
  border-bottom: 2px solid var(--decor-color);
}

.line_input:disabled {
  background-color: #d6d7d9;
  opacity: 0.4;
}

.error {
  background-color: var(--danger-color);
}

.error_message {
  font-size: 11px;
  line-height: 13px;
  color: var(--danger-color);
  margin-top: 5px;
}
 
@media (max-width: 500px) {
  .error_message {
    margin-top: 5px;
  }
}

@media (max-width: 380px) {
  .error_message {
    font-size: 10px;
    margin-top: 4px;
  }
}
